import React from 'react';
import { Container, Row, Col, } from 'reactstrap';
import { Link } from 'gatsby';

import {toggleLang, getLang} from '../services/lang';
import Layout from '../components/Layout';
import * as TXT from '../res/strings';
import * as TXTEN from '../res/strings_en';
import * as ICON from '../res/icons';
import IMG_BBIERCITO1 from '../images/Biercito_Beer_CafecitoMenu.jpeg';
import IMG_BBIERCITO2 from '../images/Biercito_Beer_Backmenu.jpeg';

class Biercito extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'ES',
    }

    this.toggleLanguage = this.toggleLanguage.bind(this);
  }

  componentDidMount(){
    if (getLang().language !== this.state.lang) {
      this.setState({lang: getLang().language});
      }
  }

  toggleLanguage(lang) {
    toggleLang(lang);
    if (lang !== this.state.lang) {
      this.setState({lang: lang});
    }
  }

  render() {
    const APP_LANG = this.state.lang;
    return (
      <Layout lang={this.state.lang} toggleLanguage={this.toggleLanguage}>
        <div className="section" id="start" style={{ marginTop: 100 }}>
					<Container>
            <Row>
							<Col className="text-center midTitle">
								<h1>
									{APP_LANG === 'EN' ? TXTEN.BIERCITO.TITLE1 : TXT.BIERCITO.TITLE1}
								</h1>
							</Col>
            </Row>
            <span className="separator_100" />
            <img src={IMG_BBIERCITO1} width="100%" height="auto"/>
            <span className="separator_100" />
            <Row />
            <Row>
							<Col lg={{ size: 10, offset: 1 }}className="text-center">
								<p><strong>
                  {APP_LANG === 'EN' ? TXTEN.BIERCITO.CONTENT_MAIN : TXT.BIERCITO.CONTENT_MAIN}
                  </strong>
								</p>
                <p className="has-text-second"><strong>
									{APP_LANG === 'EN' ? TXTEN.BIERCITO.CONTENT_MAIN2 : TXT.BIERCITO.CONTENT_MAIN2}
                  </strong></p>
							</Col>
            </Row>
            <Row />
            <span className="separator_100" />
            <img src={IMG_BBIERCITO2} width="100%" height="auto"/>
            <span className="separator_100" />
						<Row>
              <Col xl="12" className="text-center">
                <Link to="/reserve">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.CTA_RESERVE : TXT.BUTTON.CTA_RESERVE}
                  </div>
                </Link>
              </Col>
            </Row>
						<Row>
              <Col xl="12" className="text-center">
                <Link to="/">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_BACK_HOME : TXT.BUTTON.BTN_BACK_HOME}
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}

export default Biercito;
